export const data = [
	{
		to: '/',
		text: 'Our Services',
		id:'ourServices',
		show:false,
	},
	{
		to: '/',
		text: 'Contact Us',
		id:'contactUs',
		show:false,
	},
	{
		to: '/',
		text: 'About Us',
		id: 'aboutUs',
		show:false,
	},

	{
		to: '/Posts',
		text: 'posts',
		show:true,
	},
	
	{
		to: '/',
		text: 'Home',
		id: 'homePage',
		show:true,
	},
	

	
	
];
