import React,{Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import i18n from "i18next";
import XHR from 'i18next-xhr-backend';
import {initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(XHR)
  .init({
	  supportedLngs: ['en', 'ar'],
    fallbackLng: "en",
	detaction: {
		order: ['cookie','htmlTag','localStorage', 'path', 'subdomain'],
		lookupCookie: 'lang',
		caches: ['cookie'],
	},
	backend: {
		loadPath: 'assets/locales/{{lng}}/translation.json',
	},
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },

  });
//fallbackLng
const looding = () => <div>loading...</div>;

// append app to dom
ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={looding}>
			
			<App style={{background:"#e9e9e9"}}/>
		</Suspense>
	</React.StrictMode>,
	document.getElementById('root')
);
