import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { CgMenuRight } from "react-icons/cg";
import { IconContext } from "react-icons";
import logo from "./logo.png";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavIcon,
  MobileIcon,
  NavMenu,
  NavLinks,
  NavItem,
} from "./NavbarStyles.js";
import { useLocation, useHistory } from "react-router-dom";
import { data } from "../../data/NavbarData";
import { dataAR } from "../../data/NavBarDataAR";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import "./nave.css";
const Navbar = () => {
  const [show, setShow] = useState(false);
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  let history = useHistory();
  let location = useLocation();

  const handleClick = () => {
    setShow(!show);
  };

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const closeMobileMenu = (to, id) => {
    if (
      (id === "aboutUs" ||
        id === "contactUs" ||
        id === "ourServices" ||
        id === "homePage") &&
      location.pathname === "/"
    ) {
      scrollTo(id);
    }

    history.push(to);
    setShow(false);
  };

  const currentLanguagesCode = localStorage.getItem("i18nextLng") || "en ";
  const { t } = useTranslation();
  useEffect(() => {
    document.documentElement.lang = currentLanguagesCode;
  }, [currentLanguagesCode]);
  return (
    <IconContext.Provider value={{ color: "#fff" }}>
      <Nav
        dir={t("navDir")}
        className={colorChange ? "navbar colorChange" : "navbar"}
      >
        <NavbarContainer>
          <NavLogo to="/">
            <NavIcon src={logo} alt="logo" />
          </NavLogo>
          <MobileIcon onClick={handleClick}>
            {show ? <FaTimes /> : <CgMenuRight />}
          </MobileIcon>
          <NavMenu
            show={show}
            side={currentLanguagesCode === "en" ? true : false}
          >
            {currentLanguagesCode === "ar" ? (
              <NavItem key="aa">
                <NavLinks onClick={() => closeMobileMenu()}>
                  <div
                    onClick={() => {
                      i18n.changeLanguage("en");
                      document.body.dir = "ltr";
                    }}
                  >
                    English
                  </div>
                </NavLinks>
              </NavItem>
            ) : (
              <NavItem key="a">
                <NavLinks onClick={() => closeMobileMenu()}>
                  <div
                    onClick={() => {
                      i18n.changeLanguage("ar");
                      document.body.dir = "rtl";
                    }}
                  >
                    العربية
                  </div>
                </NavLinks>
              </NavItem>
            )}
            {currentLanguagesCode === "en"
              ? data.map((el, index) => (
                  <NavItem key={index}>
                    <NavLinks onClick={() => closeMobileMenu(el.to, el.id)}>
                      {el.text}
                    </NavLinks>
                  </NavItem>
                ))
              : dataAR.map((el, index) => (
                  <NavItem key={index}>
                    <NavLinks onClick={() => closeMobileMenu(el.to, el.id)}>
                      {el.text}
                    </NavLinks>
                  </NavItem>
                ))}
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
  );
};

export default Navbar;
