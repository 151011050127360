import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { IconContext } from "react-icons";
import { sliderSettings } from "../../data/FeaturesData";

import { Row, Heading, Section, TextWrapper } from "../../globalStyles";
import { useTranslation } from "react-i18next";
import "../../../src/app.css";
import {
  ButtonContainer,
  ReviewSlider,
  ImageWrapper,
  CarouselImage,
  CardButton,
} from "./FeaturesCarouselStyles";


const Carousel = () => {
  const getDataFromServer = () => {
    var requestOptions = {
      method: "GET",
      headers: {
        "trn-api-key": "NPPR9",
      },
    };
    fetch(
      "https://sky-scopes-afaq-web.com.istisharatdatabase.com/services_features",
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {

        setDataFromServer(result);
      })
      .catch((error) => console.log("error", error));
  };
  const [dataFromServer, setDataFromServer] = useState([]);
  useEffect(() => {
    getDataFromServer();
  }, []);

  let history = useHistory();
  const [sliderRef, setSliderRef] = useState(null);
  const { t } = useTranslation();
  const currentLanguagesCode = localStorage.getItem("i18nextLng") || "en ";
  return (
    <Section margin="auto" maxWidth="100%" padding="50px 70px" inverse>
      <Row justify="space-between" margin="1rem" wrap="wrap">
        <Heading  width="auto" inverse>
          {t("OurServices")}
        </Heading>
        <ButtonContainer>
          <IconContext.Provider value={{ size: "3rem", color: "#446522" }}>
            {currentLanguagesCode === "en" ? (
              <div>
                <FaArrowCircleLeft onClick={sliderRef?.slickPrev} />
                <FaArrowCircleRight onClick={sliderRef?.slickNext} />
              </div>
            ) : (
              <div>
                <FaArrowCircleRight onClick={sliderRef?.slickNext} />
                <FaArrowCircleLeft onClick={sliderRef?.slickPrev} />
              </div>
            )}
          </IconContext.Provider>
        </ButtonContainer>
      </Row>

      {currentLanguagesCode === "en" ? (
        <ReviewSlider {...sliderSettings} ref={setSliderRef}>
          {dataFromServer.map((el, index) => (
            <ImageWrapper key={index}>
              <CarouselImage src={el.services_features_path_image} />
              <TextWrapper  size="1.1rem" margin="0.4rem 0 0" weight="bold">
                {el.services_features_title_en}
              </TextWrapper>

              <CardButton
              
                onClick={() => {
                  history.push({
                    pathname: "/" + el.services_features_path,
                    state: el.services_features_no,
                  });
                }}
              >
                {t("ReadMore")}
              </CardButton>
            </ImageWrapper>
          ))}
        </ReviewSlider>
      ) : (
        <ReviewSlider {...sliderSettings} ref={setSliderRef}>
          {dataFromServer.map((el, index) => (
            <ImageWrapper key={index}>
              <CarouselImage src={el.services_features_path_image} />
              <TextWrapper  size="1.1rem" margin="0.4rem 0 0" weight="bold">
                {el.services_features_title_ar}
              </TextWrapper>

              <CardButton
              
                onClick={() => {
                  history.push({
                    pathname: "/" + el.services_features_path,
                    state: el.services_features_no,
                  });
                }}
              >
                {t("ReadMore")}
              </CardButton>
            </ImageWrapper>
          ))}
        </ReviewSlider>
      )}
    </Section>
  );
};

export default Carousel;
