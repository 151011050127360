export const sliderSettings = {
  arrows: false,
  slidesToShow: 4,
  autoplay:true,
  autoplaySpeed:1500,
  focusOnselect: false,
  accessability: false,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
      },
    },

    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
