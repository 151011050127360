import React, { useEffect, useState } from "react";
import { Container, Section } from "../../globalStyles";
import { TextField } from "@mui/material";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import CircularProgress from '@mui/material/CircularProgress';
import { motion } from "framer-motion";
import "../../../src/app.css";
import {
  ContentRow,
  TextWrapper,
  TopLine,
  Heading,
  ContentButton,
  ContentButton2,
  ImgWrapper,
  Img,
  ContentColumn,
} from "./ContactUsStyles.js";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";

export const ContactUs = ({
  primary,
  topLine,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  inverse,
  reverse,
}) => {
  const initial = { opacity: 0, y: 30 };
  const animation = useAnimation();
  const { t } = useTranslation();
  const { ref, inView } = useInView({ threshold: 0.2 });
  const [loding, setLoding] = useState(false);
  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
      });
    }
  }, [inView, animation]);
  const mySubmitHandler = (event) => {
    event.preventDefault();
    sendData();
  };
  const sendData = () => {
    setLoding(true);
    var raw = JSON.stringify({
      email_message_mobile: document.getElementById("phoneNumber").value,
      email_message_message: document.getElementById("message").value,
      email_message_email: document.getElementById("email").value,
      email_message_name: document.getElementById("name").value,
    });
    var requestOptions = {
      method: "POST",
      headers: {
        "trn-api-key": "NPPR9",
        "Content-Type": "application/json",
      },
      body: raw,
    };

    fetch(
      "https://sky-scopes-afaq-web.com.istisharatdatabase.com/email_message",
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
       // alert(result);
        setLoding(false);
        document.getElementById("FormMessage").reset();
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <Section inverse={inverse} ref={ref}>
      <Container>
        <ContentRow reverse={reverse}>
          <ContentColumn>
            <TextWrapper>
              <TopLine
             
                initial={initial}
                transition={{ delay: 0.3, duration: 0.6 }}
                animate={animation}
              >
                {topLine.text}
              </TopLine>
              <Heading
             
                initial={initial}
                transition={{ delay: 0.5, duration: 0.6 }}
                animate={animation}
                inverse={inverse}
              >
                {headline}
              </Heading>
              <motion.div
                initial={initial}
                transition={{ delay: 0.5, duration: 0.6 }}
                animate={animation}
              >
                <Form id="FormMessage" onSubmit={mySubmitHandler}>
                  <TextField
                 
                    required
                    id="email"
                    placeholder={t("email")}
                    type="email"
                    className="mb-3"
                    style={{
                      marginBottom: "15px",
                      width: "100%",
                      backgroundColor: "#f5f5f5",
                    }}
                    variant="filled"
                  />
                  <TextField
                 
                    required
                    id="name"
                    placeholder={t("name")}
                    type="text"
                    className="mb-3"
                    style={{
                      marginBottom: "15px",
                      width: "100%",
                      backgroundColor: "#f5f5f5",
                    }}
                    variant="filled"
                  />
                  <TextField
                 
                    required
                    id="phoneNumber"
                    placeholder={t("phoneNumber")}
                    type="text"
                    className="mb-3"
                    style={{
                      marginBottom: "15px",
                      width: "100%",
                      backgroundColor: "#f5f5f5",
                    }}
                    variant="filled"
                  />
                  <TextField
                 
                    required
                    id="message"
                    className="mb-3"
                    placeholder={t("message")}
                    multiline
                    style={{
                      marginBottom: "15px",
                      width: "100%",
                      backgroundColor: "#f5f5f5",
                    }}
                    variant="filled"
                  />
                <Row>
                   {loding === true ? (
                 <CircularProgress color="success" />
                  ) : null}
                </Row>
                 <Row>

                 <ContentButton
                
                    type="submit"
                    initial={initial}
                    transition={{ delay: 1, duration: 0.6 }}
                    animate={animation}
                    inverse={inverse}
                    primary={primary}
                  >
                    {t("ButtonTitleSend")}
                  </ContentButton>
                  <ContentButton2
                 
                    style={{ margin: "7px" }}
                    type="reset"
                    initial={initial}
                    transition={{ delay: 1, duration: 0.6 }}
                    animate={animation}
                    inverse={inverse}
                    primary={primary}
                  >
                    {t("ButtonTitleDlete")}
                  </ContentButton2>
                 </Row>

                </Form>
              </motion.div>
            </TextWrapper>
          </ContentColumn>
          <ContentColumn
            initial={initial}
            transition={{ delay: 0.5, duration: 0.6 }}
            animate={animation}
          >
            <ImgWrapper>
              <Img
                src={img}
                alt={alt}
                whileHover={{ rotate: 2, scale: 1.02 }}
                transition={{ duration: 0.5 }}
              />
            </ImgWrapper>
          </ContentColumn>
        </ContentRow>
      </Container>
    </Section>
  );
};
