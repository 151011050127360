import React, { useEffect } from 'react';
import { Container, Section } from '../../globalStyles';
import {ContentRow,TextWrapper,TopLine,Heading,Subtitle,ImgWrapper,ContentColumn,} from './PostsLoadingStyles.js';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';

export const PostsLoading = (props) => {
	const initial = { opacity: 0, y: 30 };
	const animation = useAnimation();

	const { ref, inView } = useInView({ threshold: 0.2 });

	useEffect(() => {
		if (inView) {
			animation.start({
				opacity: 1,
				y: 0,
			});
		}
	}, [inView, animation]);
	return (
		<Section inverse={props.inverseState} ref={ref}>
			<Container>
				<ContentRow reverse={props.reverseState}>
					<ContentColumn>
						<TextWrapper>	
						<TopLine
								initial={initial}
								transition={{ delay: 0.2, duration: 0.2 }}
								animate={animation}
							>
								<Skeleton variant="text" />
							</TopLine>						
							<Heading
								initial={initial}
								transition={{ delay: 0.3, duration: 0.2 }}
								animate={animation}
								inverse={props.inverseState}
							>
								<Skeleton variant="text" />
							</Heading>
							<Subtitle
								initial={initial}
								transition={{ delay: 0.5, duration: 0.2 }}
								animate={animation}
								inverse={props.inverseState}
							>
								<Skeleton variant="text" />
							</Subtitle>
						</TextWrapper>
					</ContentColumn>
					<ContentColumn
						initial={initial}
						transition={{ delay: 0.5, duration: 0.6 }}
						animate={animation}
					>
						<ImgWrapper>
						<CircularProgress color="success" />
						</ImgWrapper>
					</ContentColumn>
				</ContentRow>
			</Container>
		</Section>
	);
};
