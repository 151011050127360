import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { IconContext } from "react-icons";
import {sliderSettings } from "../../data/CarouselData";
import { Row, Heading, Section2, TextWrapper } from "../../globalStyles";
import {
  ButtonContainer,
  ReviewSlider,
  ImageWrapper,
  CarouselImage,
  CardButton,
} from "./CarouselStyles";
import "../../../src/app.css";
import { useTranslation } from "react-i18next";
const Carousel = () => {
  const { t } = useTranslation();
  const [sliderRef, setSliderRef] = useState(null);
  let history = useHistory();
  const getDataFromServer = () => {
    var requestOptions = {
      method: "GET",
      headers: {
        "trn-api-key": "NPPR9",
      },
    };
    fetch(
      "https://sky-scopes-afaq-web.com.istisharatdatabase.com/services_features/last/post",
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
     
        setDataFromServer(result);
      })
      .catch((error) => console.log("error", error));
  };
  const [dataFromServer, setDataFromServer] = useState([]);
  useEffect(() => {
    getDataFromServer();
  }, []);
  const currentLanguagesCode = localStorage.getItem("i18nextLng") || "en ";
  return (
    <Section2 margin="auto"  padding="50px 70px" inverse>
      <Row justify="space-between" margin="1rem" wrap="wrap">
        <Heading  width="auto" inverse>
          {t('lastPosts')}
        </Heading>
        <ButtonContainer>
          <IconContext.Provider value={{ size: "3rem", color: "#446522" }}>
            {currentLanguagesCode === "en" ? (
              <div>
                <FaArrowCircleLeft onClick={sliderRef?.slickPrev} />
                <FaArrowCircleRight onClick={sliderRef?.slickNext} />
              </div>
            ) : (
              <div>
                <FaArrowCircleRight onClick={sliderRef?.slickNext} />
                <FaArrowCircleLeft onClick={sliderRef?.slickPrev} />
              </div>
            )}
          </IconContext.Provider>
        </ButtonContainer>
      </Row>

      {currentLanguagesCode === "en" ? (
        <ReviewSlider {...sliderSettings} ref={setSliderRef}>
          {dataFromServer.map((el, index) => (
            <ImageWrapper key={index}>
              <CarouselImage src={el.post_path_image} />
              <TextWrapper  size="1.1rem" margin="0.4rem 0 0" weight="bold">
                {el.post_title_en}
              </TextWrapper>

              <CardButton
              
                onClick={() => {
                  history.push({
                    pathname: "/" + el.services_features_path,
                    state: el.services_features_no,
                  });
                }}
              >
                {t("ReadMore")}
              </CardButton>
            </ImageWrapper>
          ))}
        </ReviewSlider>
      ) : (
        <ReviewSlider {...sliderSettings} ref={setSliderRef}>
          {dataFromServer.map((el, index) => (
            <ImageWrapper key={index}>
              <CarouselImage src={el.post_path_image} />
              <TextWrapper  size="1.1rem" margin="0.4rem 0 0" weight="bold">
                {el.post_title_ar}
              </TextWrapper>

              <CardButton
              
                onClick={() => {
                  history.push({
                    pathname: "/" + el.services_features_path,
                    state: el.services_features_no,
                  });
                }}
              >
                {t("ReadMore")}
              </CardButton>
            </ImageWrapper>
          ))}
        </ReviewSlider>
      )}
    </Section2>
  );
};

export default Carousel;
