import React,{useEffect} from 'react';
import GlobalStyle from './globalStyles';
import { BrowserRouter as Router, Switch, Route,Redirect } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
//Pages
import Home from './pages/Home';
import AllPosts from './pages/AllPosts';
import Services from './pages/Service';
import Footer from './components/Footer/Footer';
import { useTranslation } from "react-i18next";
import "./app.css";

function App() {
	const {t} = useTranslation();
	useEffect(() => {
		document.body.dir = t("dir");
	},[]);
	return (
		<div className={t("clas")}>
			<Router>
			<GlobalStyle />
			<Navbar />
			<Switch>
				<Route path="/" exact component={Home} />
				<Route path="/posts" exact component={AllPosts} />
				<Route path="/service/:id" exact component={Services} />
				<Redirect from="/" to="/" />
			</Switch>
			<Footer />
		</Router>
		</div>
		
	);
}

export default App;
