import React from "react";
import { Button, Container, MainHeading } from "../../globalStyles";
import {HeroVideo,HeroSection,HeroText,ButtonWrapper,} from "./HeroStyles";
import { useTranslation } from "react-i18next";
import "../../../src/app.css";
const Hero = () => {
  const {t} = useTranslation();
  const looding = () => <div>loading...</div>;
  return (
    <HeroSection>
      <div Suspense fallback={looding}>
        <HeroVideo src="./assets/hero.mp4" autoPlay loop muted preload playsInline />
      </div>
      
      <Container>
        <MainHeading >{t('SKYSCOPES')}</MainHeading>
        <HeroText >{t('Information_Technology_Tel')}</HeroText>
        <ButtonWrapper>
          {/* <Link to="signup">
            <Button>Get Started</Button>
          </Link> */}
          {/* <Button>Get Started</Button> */}
        </ButtonWrapper>
      </Container>
    </HeroSection>
  );
};

export default Hero;
