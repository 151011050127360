import React, { useState, useEffect, Suspense } from "react";
import { Posts } from "../components/Posts/Posts";
import { PostsService } from "../components/PostsService/PostsService";
import { PostsLoading } from "../components/PostsLoading/PostsLoading";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function Services(props) {
  const { state } = props.location;
  // const getDataFromServer = async () => {
  //   var requestOptions = {
  //     method: "GET",
  //     headers: {
  //       "trn-api-key": "NPPR9",
  //     },
  //   };
  //   await fetch(
  //     `https://sky-scopes-afaq-web.com.istisharatdatabase.com/services_features/${state}`,
  //     requestOptions
  //   )
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setDataFromServer(result);
  //       setLoading(false);
  //     })
  //     .catch((error) => console.log("error", error));
  // };
  const getDataFirstPost = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        "trn-api-key": "NPPR9",
      },
    };
    await fetch(
      `https://sky-scopes-afaq-web.com.istisharatdatabase.com/services_features/service/${state}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
       
        setDataFirstPost(result);
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };
  const [DataFirstPost, setDataFirstPost] = useState([]);
  //const [dataFromServer, setDataFromServer] = useState([]);
  const [loading, setLoading] = useState(true);
  //const [loading2, setLoading2] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    getDataFirstPost();
    //getDataFromServer();
  }, []);
  let inverse, reverse;
  const looding = () => <div>loading...</div>;
  return (
    <Suspense fallback={looding}>
      {loading ? (
        <div>
          <PostsLoading reverseState={true} inverseState={false} />
          <PostsLoading reverseState={true} inverseState={true} />
        </div>
      ) : (
        <div>
          <div>
            <PostsService
              key="first"
              data={DataFirstPost.header[0]}
              reverseState={true}
              inverseState={false}
            />
          </div>
          {DataFirstPost.body.map((item, index) => {
            if (index % 2 != 0) {
              reverse = true;
              inverse = false;
            } else {
              reverse = true;
              inverse = true;
            }
            return (
              <Posts
                key={index}
                data={item}
                reverseState={reverse}
                inverseState={inverse}
              />
            );
          })}
        </div>
      )}
    </Suspense>
  );
}
