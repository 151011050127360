export const dataAR = [
	{
		to: '/',
		text: 'خدماتنا',
		id:'ourServices',
		show:false,
	},
	{
		to: '/',
		text: 'تواصل معنا',
		id:'contactUs',
		show:false,
	},
	{
		to: '/',
		text: 'حولنا',
		id: 'aboutUs',
		show:false,
	},
	{
		to: '/posts',
		text: 'المنشورات',
		show:true,
	},
	
	{
		to: '/',
		text: 'الرئيسية',
		id: 'homePage',
		show:true,
	},
	
	
	
];
