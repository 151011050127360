import React, { useEffect } from "react";
import { Container, Section } from "../../globalStyles";
import "../../../src/app.css";
import {
  ContentRow,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
  ContentColumn,
} from "./PostsStyles.js";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";

export const Posts = (props) => {
  const initial = { opacity: 0, y: 30 };
  const animation = useAnimation();
  const { t } = useTranslation();
  const { ref, inView } = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
      });
    }
  }, [inView, animation]);
  const currentLanguagesCode = localStorage.getItem("i18nextLng") || "en ";
  return (
    <Section inverse={props.inverseState} ref={ref}>
      <Container>
        <ContentRow reverse={props.reverseState}>
          <ContentColumn>
            <TextWrapper>
              <TopLine
                
                initial={initial}
                transition={{ delay: 0.3, duration: 0.6 }}
                animate={animation}
              >
                {currentLanguagesCode === "en"
                  ? props.data.services_features_title_en
                  : props.data.services_features_title_ar}
              </TopLine>
              <Heading
			  
                initial={initial}
                transition={{ delay: 0.5, duration: 0.6 }}
                animate={animation}
                inverse={props.inverseState}
              >
                {currentLanguagesCode === "en"
                  ? props.data.post_title_en
                  : props.data.post_title_ar}
              </Heading>
              <Subtitle
			  
                initial={initial}
                transition={{ delay: 0.7, duration: 0.6 }}
                animate={animation}
                inverse={props.inverseState}
              >
                {currentLanguagesCode === "en"
                  ? props.data.post_description_en
                  : props.data.post_description_ar}
              </Subtitle>
            </TextWrapper>
          </ContentColumn>
          <ContentColumn
            initial={initial}
            transition={{ delay: 0.5, duration: 0.6 }}
            animate={animation}
          >
            <ImgWrapper>
              <Img
                src={props.data.post_path_image}
                alt={<h1>loding</h1>}
                whileHover={{ rotate: 2, scale: 1.02 }}
                transition={{ duration: 0.5 }}
              />
            </ImgWrapper>
          </ContentColumn>
        </ContentRow>
      </Container>
    </Section>
  );
};
