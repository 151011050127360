import React from 'react';
import Carousel from '../components/Carousel/Carousel';
import { Content } from '../components/Content/Content';
import { ContactUs } from '../components/ContactUs/ContactUs';
import FeaturesCarousel from '../components/FeaturesCarousel/FeaturesCarousel';
import Hero from '../components/Hero/Hero';
import { heroOne, heroTwo, heroThree } from '../data/HeroData';
import { heroOneAR, heroTwoAR, heroThreeAR } from '../data/HeroDataAR';
// Hero Feature Content Carousel

const Home = () => {
	const currentLanguagesCode = localStorage.getItem("i18nextLng") || "en ";
	return (
		<div id="homePage">
			<Hero />
			<div id="ourServices">
				<FeaturesCarousel />
			</div >
			<div id="aboutUs">
				{currentLanguagesCode === "en" ?<Content {...heroOne} />:<Content {...heroOneAR} /> }
			</div>
			{currentLanguagesCode === "en" ?<Content {...heroThree} /> :<Content {...heroThreeAR} /> }
			<div id="contactUs">
			{currentLanguagesCode === "en" ?<ContactUs {...heroTwo} /> :<ContactUs {...heroTwoAR} />}
			</div>
			<Carousel />
			
			
		</div>
	);
};

export default Home;
