import React, { useState, useEffect, Suspense } from "react";
import { Posts } from "../components/Posts/Posts";
import { PostsLoading } from "../components/PostsLoading/PostsLoading";


export default function AllPosts() {
  const getDataFromServer = () => {
    var requestOptions = {
      method: "GET",
      headers: {
        "trn-api-key": "NPPR9",
      },
    };
    fetch(
      "https://sky-scopes-afaq-web.com.istisharatdatabase.com/post",
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
      
        setDataFromServer(result);
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };
  const [dataFromServer, setDataFromServer] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0)
    getDataFromServer();
  }, []);
  let inverse, reverse;
  const looding = () => <div>loading...</div>;
  return (
    <Suspense fallback={looding}>
      {loading||dataFromServer===[] || dataFromServer===undefined ? (
        <div>
         
          <PostsLoading  reverseState={true}
                inverseState={false} />
          <PostsLoading  reverseState={true}
                inverseState={true} />
                <PostsLoading  reverseState={true}
                inverseState={false} />
          <PostsLoading  reverseState={true}
                inverseState={true} />
        </div>
      ) : (
        <div>
          {dataFromServer.map((item, index) => {
            if (index % 2 !== 0) {
              reverse = true;
              inverse = true;
            } else {
              reverse = true;
              inverse = false;
            }
            return (
              <Posts
                key={index}
                data={item}
                reverseState={reverse}
                inverseState={inverse}
              />
            );
          })}
        </div>
      )}
    </Suspense>
  );
}
