
export const sliderSettings = {
	arrows: false,
	slidesToShow: 3,
	autoplay:true,
  autoplaySpeed:1500,
	focusOnselect: false,
	accessability: false,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 2,
			},
		},

		{
			breakpoint: 900,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};
