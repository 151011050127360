export const heroOneAR = {
	reverse: false,
	inverse: false,
	topLine: {
		text: 'تأسست عام 2015',
	},
	headline: "معلومات عنا",
	description: 'كانت البداية عام 2005 مع اثنين من المهندسين المبدعين وذاتيي الحافز والابداع ، هما قادة اليوم . نتطلع لتقديم حلول ذكية قائمة على تكنولوجيا المعلومات مدمجة مع مفاهيم إنترنت الأشياء وتطبيقات الهاتف المحمول في مجالات الحياة كافة',
	buttonLabel: 'اقرأ أكثر',
	imgStart: 'start',
	img: './assets/svg/Deal.svg',
	start: 'true',
};

export const heroTwoAR = {
	reverse: false,
	inverse: false,
	topLine: {
		text: 'جاهزين لتنفيذ مشروعك',
	},
	headline: 'تواصل معنا',
	description: 'يتمتع عملاؤنا بأفضل تجربة في العمل معنا',
	buttonLabel: 'إرسال',

	linkTo: '/more',
	imgStart: 'start',
	img: './assets/svg/Contact-Us.png',
	start: 'true',
};

export const heroThreeAR = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'لأفضل مستقبل',
	},
	headline: 'الهدف ',
	description:
		'هدفنا هو تقديم حلولنا الذكية , كذلك مفاهيم تقنية متقدمة لجميع المجالات الحيوية مثل : الأعمال العسكرية والمنشأت الحكومية , المدنية وحتى الاستخدامات الشخصية .',
	buttonLabel: 'اقرأ أكثر',

	linkTo: '/download',
	imgStart: 'start',
	img: './assets/svg/ChartUp.svg',
	start: 'true',
};
